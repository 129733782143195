<!-- 首页 -->
<template>
    <div class="page-about-us f-wrap f-f0">
        <div class="ab-left-group f-ibt">
            <!--
            <div class="h5-about-banner about-banner">
                <img src="../assets/mobile/about_banner@2x.png" />
            </div>
            -->
            <div class="about-group">
                <div class="title"> 
                    <div class="txt f-ibm">关于我们</div>    
                </div>
                <div class="cont">
                    <p>安贸通，海量一手货源<br></p>
                    <!--
                    <div class="pc-about-banner about-banner">
                        <img src="../assets/pc/about_banner@2x.png" />
                    </div>
                    -->
                    <p>安贸通，广受海内外用户好评，主流的货源发布与搜索。产品直通莆田、广州、义乌、越南、台湾等货源地厂商，让用户快速找到一手货源，轻松省钱，高效赚钱。安贸通与数千个商家实现流量互通，确保货源新鲜和客源不断。此外，安贸通具有供求信息发布、搜索、评论、关注，快速转发朋友圈等功能，为您打造便捷的货源索取和电商营销体验。</p>
                    <p><br></p>
                    <p>安贸通，货源通</p>
                    <p>下载安贸通，通通省钱！直面百万一手货源厂商，不用再抢优惠券，不用苦等购物节，这里，全年没有中间商赚差价！用更低的价格享受更好的东西，从业余买手，到专业买手，从安贸通开始！</p>
                    <p><br></p>
                    <p>安贸通，生意通</p>
                    <p>我们负责流量，您负责留人！千万流量，百万客源，全通道流量接口，与海内外数百家知名媒体合作，优质商家流量扶持，24小时不间断货源自动展示。安贸通，您值得托付的电商营销助手。</p>
                    <p><br></p>
                    <p>【特色频道，为您赚多多】</p>
                    <p>1.新品专区：时尚潮款发布，应季新品任您选，带您引领流行风向标，潮流快人一步。</p>  
                    <p>2.微商专区：微商聚合阵地，无门槛享受渠道价，各大品牌分销，让人人都成为微商。</p>  
                    <p>3.一件代发：1件起就能代发货，让供应商为您跑腿，在家躺着赚！</p>  
                    <p>4.零售专区：精选优质商品，低价即可享受专柜品质，全场深度折扣。</p>  
                    <p>5.代购专区：海外买手云集，口碑爆棚，货源地采购直播，比自己买更便宜。</p>  
                    <p>6.代理商专区：各级代理商汇聚，为你打通销售渠道，让省钱变成赚钱。</p>  
                    <p>7.工厂直销：低价，货源一手直供，每天帮助千万用户节省数亿元，您就是一手。</p>  
                </div>
            </div>
        </div>
        <!-- 右边 -->
        <div class="ab-right-group f-ibt">
            <div class="area-group">
                <div class="ag-tit"><span class="red">联系</span>我们</div>
                <div class="it-txt">© 2022 安贸通 版权所有</div>
                <div class="it-txt">联系电话：0592-6210 307</div>
                <div class="it-txt">公司名称：厦门安贸通科技有限公司</div>
                <div class="way f-f0">
                    <div class="wx f-ibm f-btn"></div>
                    <div class="qq f-ibm f-btn"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: "aboutus"
};
</script>
<style>
body{
    background-color: white;
}
</style>
<style lang="less" scoped>
.page-about-us{
    width: 1200px;
    background-color: white;
    padding-top: 30px;
    .ab-left-group{
        width: 880px;
        margin: 0 auto;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
    }
    .ab-right-group{
        width: 300px;
        border-top: 3px solid #F02420;
        background-color: #F7F7F7;
        margin-left: 20px;
        .area-group{
            padding: 20px 14px;
            box-sizing: border-box;
            .ag-tit{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #F02420;
                margin-bottom: 18px;
                .red{
                    color: #F02420;
                }
            }
            .it-txt{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #777777;
                margin: 8px 0;
            }
            .way{
                padding-top: 8px;
                .wx{
                    width: 98px;
                    height: 28px;
                    background: url(../assets/pc/wx_btn@2x.png) no-repeat;
                    background-size: 100% 100%;
                }
                .qq{
                    width: 96px;
                    height: 28px;
                    background: url(../assets/pc/qq_btn@2x.png) no-repeat;
                    background-size: 100% 100%;
                    margin-left: 10px;
                }
            }
        }
    }
}
.pc-about-banner{
    margin: 12px 0;
}
.about-banner{
    img{
        width: 100%;
    }
}
.h5-about-banner{
    display: none;
}
.about-group{
        padding: 20px;
        box-sizing: border-box;
        .title{
            width: 100%;
            text-align: center;
            .txt{
                font-size:20px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #222222;
                &::after{
                    content: '';
                    display: block;
                    height: 4px;
                    background: #F02420;
                    margin-top: 14px;
                }
            }
        }
        .cont{
            margin-top: 23px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
            line-height: 30px;
            p{
                text-indent: 30px;
            }
        }
    }
@media screen and (max-width:1199px) { 
    .page-about-us{
        width: 100% !important;
    }
    .ab-left-group{
        display: block;
    }
    .ab-right-group{
        display: none;
    }
}
@media screen and (max-width:880px) { 
    .page-about-us{
        .ab-left-group{
            width: 100%;
        }
    }
}
//移动处理
@media screen and (max-width:750px) { 
.page-about-us{
    background-color: white;
    padding-top: 0px;
    .h5-about-banner{
        display: block;
    }
    .pc-about-banner{
        display: none;
        width: 0;
        height: 0;
        visibility: hidden;
    }
    .ab-left-group{
        width: 100%;
        border: none;
        box-sizing: border-box;
    }
    .ab-right-group{
        display: none;
    }
    .about-group{
        padding: 20px;
        box-sizing: border-box;
        .title{
            width: 100%;
            text-align: center;
            .txt{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #222222;
                &::after{
                    content: '';
                    display: block;
                    height: 3px;
                    background: #F02420;
                    margin-top: 7px;
                }
            }
        }
        .cont{
            margin-top: 18px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
            line-height: 24px;
            p{
                text-indent: 24px;
            }
        }
    }
}
}
</style>
